import firebase from "firebase/app";
import { useLocation } from "react-router-dom";

// const baseURL = 'https://beta.api.grafiame.com.br/';
const baseURL = "https://api.grafiame.com.br/";
// const baseURL = "http://localhost:8080/";

function goTo(path) {
  document.location = document.location.origin + "/" + path;
}

function credentialsSetter(email, password) {
  email = email;
  password = password;
}

function getDate(date, includeHour) {
  if (date) {
    const meses = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];
    var data = new Date(date);
    let dataFormatada =
      data.getDate() + " " + meses[data.getMonth()] + " " + data.getFullYear();

    if (includeHour) {
      dataFormatada =
        dataFormatada + " " + data.getHours() + ":" + data.getMinutes();
    }

    return dataFormatada;
  } else {
    return "";
  }
}

function VerifyAuth(bearer, setBearer, callback) {
  firebase.auth().onAuthStateChanged(function (currentUser) {
    if (currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (tokenA) {
          callback(tokenA, currentUser);
        });
    } else {
      var xhr = new XMLHttpRequest();
      xhr.addEventListener("load", () => {
        if (xhr.status != 200) {
          // goTo("login");
        } else {
          var tokenP = (JSON.parse(xhr.responseText) || {}).tokenP;
          setBearer(tokenP);
          firebase
            .auth()
            .signInWithCustomToken(tokenP)
            .then((currentUser) => {
              VerifyAuth(bearer, setBearer, callback);
            })
            .catch((error) => {
              var errorCode = error.code;
              var errorMessage = error.message;
            });
        }
      });
      xhr.open("GET", baseURL + "auth", true);
      xhr.setRequestHeader("authorization", "Bearer " + bearer);
      xhr.send();
    }
  });
}

export { credentialsSetter, VerifyAuth, goTo, getDate, baseURL };
