import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import EssayCorrector from './EssayCorrector';
import EssayViewer from './EssayViewer';
import Login from './Login';
import Organizations from './Organizations';
import reportWebVitals from './reportWebVitals';
import Groups from './Groups';
import Essays from './Essays';
import Texts from './Texts';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
import {
  FirebaseAuthProvider,
  IfFirebaseUnAuthed,
  IfFirebaseAuthed,
  FirebaseAuthConsumer,
  IfFirebaseAuthedAnd
} from "react-firebase-auth-provider";

var firebaseConfig = {
  apiKey: "AIzaSyDdUP_VWIjOY5jBz62_Ki8b5W6mhKLTnMo",
  authDomain: "grafiame-online.firebaseapp.com",
  projectId: "grafiame-online",
  storageBucket: "grafiame-online.appspot.com",
  messagingSenderId: "19426931887",
  appId: "1:19426931887:web:487c6fc96231ba9166d05a"
};

// var firebaseConfig = {
//   apiKey: "AIzaSyCv7YQQAD1B2Tb-xpB8QJBssK4GRWl0LQk",
//   authDomain: "avian-mile-297701.firebaseapp.com",
//   projectId: "avian-mile-297701",
//   storageBucket: "avian-mile-297701.appspot.com",
//   messagingSenderId: "672785520874",
//   appId: "1:672785520874:web:24e7d687bc73dec4915f93",
//   measurementId: "G-E8BD3V389Q"
// };


function Paginator() {

  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    } else {
      firebase.app();
    }
  }, [])

  return (
    <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
      <Router>
        <Switch>

          <Route path="/login">
            <Login />
          </Route>

          <Route exact path="/">
              <Redirect to="/login" />
          </Route>


          <Route path="/essayCorrector">
            <EssayCorrector />
          </Route>

          <Route path="/essayViewer">
            <EssayViewer />
          </Route>

          <Route path="/organizations">
            <Organizations />
          </Route>

          <Route path="/groups">
            <Groups />
          </Route>

          <Route path="/essays">
            <Essays />
          </Route>

          <Route path="/texts">
            <Texts />
          </Route>

        </Switch>
      </Router>
    </FirebaseAuthProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Paginator/>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
