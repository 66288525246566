import React, { useState, useEffect } from 'react';
import 'fontsource-roboto';
import IconButton from '@material-ui/core/IconButton';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import Tooltip from '@material-ui/core/Tooltip';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Typography from '@material-ui/core/Typography';
import interrogation from './icons/interrogation.png';
import correct from './icons/correct.png';
import like from './icons/like.png';
import wrong from './icons/wrong.png';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Settings from './Settings';
import SettingsIcon from '@material-ui/icons/Settings';
import Popover from '@material-ui/core/Popover';
import { BrowserRouter as Router, useLocation } from "react-router-dom";

function ToolBar(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [stickerPopover, setStickerPopover] = React.useState(false);
  const [selectedTab, setSelectedTab] = useState('comments');
  const [selectedBackgroudRect, setSelectedBackgoundRect] = useState(1);
  const [selectedBorderRect, setSelectedBorderRect] = useState(1);
  const [settings, setSettings] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);


  

  const GrafiameButton = withStyles((theme) => ({
    root: {
      width: '200px',
      color: theme.palette.getContrastText('rgb(54,54,54)'),
      backgroundColor: 'rgb(64,64,64)',
      '&:hover': {
        backgroundColor: 'rgb(45,45,45)',
      },
    },
  }))(Button);

  useEffect(() => {
    
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function setSticker (sticker) {
    props.setSelectedSticker(sticker);
    setStickerPopover(false);
  }

  function setBack(x, color) {
    setSelectedBackgoundRect(x);
    props.setDefaultBackgroundColor(color);
  }

  function setBorder(x, color) {
    setSelectedBorderRect(x);
    props.setDefaultBorderColor(color);
  }

  const open = Boolean(anchorEl);

  var settingsDialog = (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div style={{width: '300px'}}>
        <Settings
          setSelectedTool={props.setSelectedTool} 
          setShowElements={props.setShowElements}
          showElements={props.showElements}
        />
      </div>
      
    </Popover>
  );

  var dialog = (
    <Dialog aria-labelledby="simple-dialog-title" open={stickerPopover} onClose={() => setStickerPopover(false)}>
      <DialogTitle id="simple-dialog-title">Inserir figurinha</DialogTitle>
      <div style={{width: '300px', margin: '10px'}}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>

          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={styles.heading}>Elogios</Typography>
          </AccordionSummary>
          <div style={{display: 'flex'}}>
            <Paper elevation={3} style={styles.sticker} onClick={() => {setSticker('correct')}}> 
              <img id='essay' src={correct} draggable={false} alt="logo" style={{width: '64px', userSelect: 'none'}}/>
            </Paper>
            <Paper elevation={3} style={styles.sticker} onClick={() => {setSticker('like')}}> 
              <img id='essay' src={like} draggable={false} alt="logo" style={{width: '64px', userSelect: 'none'}}/>
            </Paper>
          </div>
        </Accordion>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>

          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={styles.heading}>Críticas/Sugestões</Typography>
          </AccordionSummary>
          <div style={{display: 'flex'}}>
            <Paper elevation={3} style={styles.sticker} onClick={() => {setSticker('wrong')}}> 
              <img id='essay' src={wrong} draggable={false} alt="logo" style={{width: '64px', userSelect: 'none'}}/>
            </Paper>
            <Paper elevation={3} style={styles.sticker} onClick={() => {setSticker('interrogation')}}> 
              <img id='essay' src={interrogation} draggable={false} alt="logo" style={{width: '64px', userSelect: 'none'}}/>
            </Paper>
          </div>
        </Accordion>
      </div>
    </Dialog>
  );

  return (
    <div>
      
      <div style={styles.mainBar}>

        <div style={{position: 'absolute', left: '50px', top: '4px', display: 'flex', height: '33px'}}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            style={{width: '100px', fontWeight: 600, textAlign: 'center'}}
            value={props.zoom}
            onChange={(ev) => {props.setZoom(ev.target.value)}}
          >
            <MenuItem value={100}>100%</MenuItem>
            <MenuItem value={125}>125%</MenuItem>
            <MenuItem value={150}>150%</MenuItem>
            <MenuItem value={175}>175%</MenuItem>
            <MenuItem value={200}>200%</MenuItem>
          </Select>

          <div style={styles.iconHolder} >
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => { if (props.zoom < 200) {props.setZoom(props.zoom + 25) }}}>
                <AddCircleIcon style={styles.zoomIcon} />  
            </IconButton>
          </div>

          <div style={{...styles.iconHolder, marginLeft: '-15px'}} >
            <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => { if (props.zoom > 100) {props.setZoom(props.zoom - 25) }}}>
                <RemoveCircleIcon style={styles.zoomIcon} />  
            </IconButton>
          </div>
          <div style={{...styles.iconHolder}} >
              <Typography
                variant="h6"
                style={{paddingLeft: "20px" }}
              >
                Aluno: {query.get('userName')} - Turma: {query.get('groupName')}
              </Typography>
          </div>
        </div>

        {props.hideSecondBar ? <div/> :
          <div style={{width: '600px', display: 'flex'}}>
            <div style={{...styles.tabButton, backgroundColor: selectedTab === 'comments' ? 'rgb(64,64,64)' : '', }}>
              <GrafiameButton variant="contained" onClick={() => { setSelectedTab('comments'); props.setSelectedTool('commentArea') }} disableElevation>
                Comentários
              </GrafiameButton>
            </div>

            <div style={{...styles.tabButton, backgroundColor: selectedTab === 'quick' ? 'rgb(64,64,64)' : '', }}>
              <GrafiameButton variant="contained" onClick={() => setSelectedTab('quick')} disableElevation>
                Acesso rápido
              </GrafiameButton>
            </div>

            <div style={{position: 'absolute', right: '50px', top: '-12px'}}>
              <IconButton color="primary" aria-label="upload picture" component="span" onClick={(ev) => {setSettings(true); handleClick(ev)}}>
                    <SettingsIcon style={styles.settingsIcon} />  
              </IconButton>
            </div>
            {settingsDialog}
          </div>
        }
          

        
        
        
      </div>

      {props.hideSecondBar ? <div/> :
        <div style={styles.secondaryBar}>

          {
            selectedTab === 'comments' ?

            <div style={{display: 'flex'}}>

              <div style={{width: '20vw', height: '30px'}}>
              </div>

              <div style={{alignSelf: 'center', display: 'flex'}}>

                  <div style={{...styles.rectHolder, backgroundColor: selectedBackgroudRect === 1 ? 'rgb(200,200,200)' : 'rgb(100,100,100)'}} onClick={() => setBack(1, 'rgba(255,0,0,0.5)')}>
                    <div style={{...styles.backgroundRect, backgroundColor: 'rgba(255,0,0,1)'}} />
                  </div>

                  <div style={{...styles.rectHolder, backgroundColor: selectedBackgroudRect === 2 ? 'rgb(200,200,200)' : 'rgb(100,100,100)'}} onClick={() => setBack(2, 'rgba(245,166,35,0.5)')}>
                    <div style={{...styles.backgroundRect, backgroundColor: 'rgba(245,166,35,1)'}} />
                  </div>

                  <div style={{...styles.rectHolder, backgroundColor: selectedBackgroudRect === 3 ? 'rgb(200,200,200)' : 'rgb(100,100,100)'}} onClick={() => setBack(3, 'rgba(248,231,28,0.5)')}>
                    <div style={{...styles.backgroundRect, backgroundColor: 'rgba(248,231,28,1)'}} />
                  </div>

                  <div style={{...styles.rectHolder, backgroundColor: selectedBackgroudRect === 4 ? 'rgb(200,200,200)' : 'rgb(100,100,100)'}} onClick={() => setBack(4, 'rgba(139,87,42,0.5)')}>
                    <div style={{...styles.backgroundRect, backgroundColor: 'rgba(139,87,42,1)'}} />
                  </div>

                  <div style={{...styles.rectHolder, backgroundColor: selectedBackgroudRect === 5 ? 'rgb(200,200,200)' : 'rgb(100,100,100)'}} onClick={() => setBack(5, 'rgba(126,211,33,0.5)')}>
                    <div style={{...styles.backgroundRect, backgroundColor: 'rgba(126,211,33,1)'}} />
                  </div>

                  


                  <div style={{...styles.rectHolder, marginLeft: '30px', backgroundColor: selectedBorderRect === 1 ? 'rgb(200,200,200)' : 'rgb(100,100,100)'}} onClick={() => setBorder(1, 'rgba(255,0,0,1)')}>
                    <div style={{...styles.borderRect, borderColor: 'rgba(255,0,0,1)'}} />
                  </div>

                  <div style={{...styles.rectHolder, backgroundColor: selectedBorderRect === 2 ? 'rgb(200,200,200)' : 'rgb(100,100,100)'}} onClick={() => setBorder(2, 'rgba(245,166,35,1)')}>
                    <div style={{...styles.borderRect, borderColor: 'rgba(245,166,35,1)'}} />
                  </div>

                  <div style={{...styles.rectHolder, backgroundColor: selectedBorderRect === 3 ? 'rgb(200,200,200)' : 'rgb(100,100,100)'}} onClick={() => setBorder(3, 'rgba(248,231,28,1)')}>
                    <div style={{...styles.borderRect, borderColor: 'rgba(248,231,28,1)'}} />
                  </div>

                  <div style={{...styles.rectHolder, backgroundColor: selectedBorderRect === 4 ? 'rgb(200,200,200)' : 'rgb(100,100,100)'}} onClick={() => setBorder(4, 'rgba(139,87,42,1)')}>
                    <div style={{...styles.borderRect, borderColor: 'rgba(139,87,42,1)'}} />
                  </div>

                  <div style={{...styles.rectHolder, backgroundColor: selectedBorderRect === 5 ? 'rgb(200,200,200)' : 'rgb(100,100,100)'}} onClick={() => setBorder(5, 'rgba(126,211,33,1)')}>
                    <div style={{...styles.borderRect, borderColor: 'rgba(126,211,33,1)'}} />
                  </div>

              </div>

            </div>

            :

            <div style={{display: 'flex'}}>

              <div style={{width: '20vw', height: '30px'}}>
              </div>

              <Button variant="contained" style={styles.quickComment} onClick={() => props.setQuickComment(1)}>Crase</Button>
              <Button variant="contained" style={styles.quickComment} onClick={() => props.setQuickComment(2)}>Pontuação</Button>
              <Button variant="contained" style={styles.quickComment} onClick={() => props.setQuickComment(3)}>Ortografia</Button>
              <Button variant="contained" style={styles.quickComment} onClick={() => props.setQuickComment(4)}>Concordância</Button>

              <div style={styles.iconHolder} >
                <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => { props.setSelectedTool('sticker'); setStickerPopover(true)}}>
                  <Tooltip title="Figurinhas">
                    <EmojiEmotionsIcon style={{fontSize: 30, color: 'rgb(224,224,224)',margin: 10}} />  
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          }
          

          {dialog}
        </div>
      }

    </div>
  );
}

/*



*/

const styles = {
  mainBar: {
    height: '40px',
    minHeight: '40px',
    backgroundColor: 'rgb(6,214,160)',
    flexDirection: "row-reverse",
    display: 'flex'
  },
  secondaryBar: {
    height: '40px',
    minHeight: '40px',
    backgroundColor: 'rgb(64,64,64)',
    display: 'flex'
  },
  icon: {
    fontSize: 40,
    color: 'rgb(36,35,37)',
    margin: 10
  },
  zoomIcon: {
    fontSize: 30,
    color: 'rgb(64,64,64)',
  },
  settingsIcon: {
    fontSize: 40,
    color: 'rgb(64,64,64)',
  },
  selectedIcon: {
    fontSize: 40,
    color: 'rgb(153,51,204)',
    margin: 10
  },
  iconHolder: {
    alignContent: 'center',
    alignSelf: 'center'
  },
  logo: {
    userSelect: 'none',
    height: '50px',
    position: 'absolute',
    top: '9px',
    left: '10px',
  },
  heading: {
    fontSize: '15px',
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  sticker: {
    margin: '10px',
    cursor: 'pointer',
    height: '72px',
    width: '72px',
    paddingTop: '7px',
    paddingLeft: '7px'
  },
  EyeIcon: {
    userSelect: 'none',
    fontSize: 40,
    color: 'rgb(36,35,37)',
    marginBottom: 10,
    position: 'absolute',
    top: 2,
    left: '280px',
  },
  tabButton: {
    height: '100%',   
    marginTop: '2px', 
    width: '200px', 
    borderTopRightRadius: '2px', 
    borderTopLeftRadius: '5px',
    marginRight: '10px'
  },
  rectHolder: {
    width: '60px', 
    height: '30px', 
    backgroundColor: 'rgb(100,100,100)', 
    display: 'flex', 
    marginLeft: '10px',
    cursor: 'pointer'
  },
  backgroundRect: {
    alignSelf: 'center', 
    height: '24px', 
    width: '54px', 
    marginLeft: '3px'
  },
  borderRect: {
    borderWidth: '2px', 
    borderStyle: 'solid', 
    alignSelf: 'center', 
    height: '22px', 
    width: '50px', 
    marginLeft: '3px'
  },
  quickComment: {
    height: '25px', 
    alignSelf: 'center', 
    marginLeft: '5px'
  }

};

export default ToolBar;
