import React, { useState, useEffect } from 'react';
import 'fontsource-roboto';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import firebase from "firebase/app";
import {VerifyAuth, goTo, baseURL} from './webHandler';
import {useSessionStorage} from "react-use-storage";

function Organizations() {
	const [organizations, setOrganizations] = useState([]);
	const [requestDone, setRequestDone] = useState(false);
	const [bearer, setBearer] = useSessionStorage("bearer");
	
  useEffect(() => {
	setRequestDone(false);
	VerifyAuth(bearer, setBearer, function (tokenA, currentUser){
		var xhr = new XMLHttpRequest();
			xhr.addEventListener('load', () => {
				setRequestDone(true);
				setOrganizations(JSON.parse(xhr.responseText));
			});
			xhr.open("GET", baseURL+'u/'+currentUser.uid+'/o/list', true);
			xhr.setRequestHeader("authorization", 'Bearer ' + tokenA);
			xhr.send();
	})
		
  }, []);

  const getCardColor = function (o){
	if (o.style && o.style.colors && o.style.colors.primary){
		return 'rgba('+o.style.colors.primary.r+','+o.style.colors.primary.g+','+o.style.colors.primary.b+','+o.style.colors.primary.a+')';
	} else {
		return 'white'
	}
  }

  const organizationClick = function(oid){
	goTo('groups?oid='+oid);
  }

  return (
    <div style={styles.outterDiv}>

		<div style={styles.topBar}>	
			<Typography variant='h3' style={{paddingTop: '8px', paddingLeft: '20px'}}>
				Organizações
			</Typography>
		</div>

		<div style={styles.innerContent}>
			{organizations.map(function(o) {
				var chips = o.info && o.info.tags ? o.info.tags.map(function (t){
					return (
						<Chip size="small" label={t} style={{marginRight: '5px'}} />
					);
				}) : <div/>
				return (
			
				<Paper 
					elevation={3}
					style={styles.card} 
					onClick={() => organizationClick(o.oid)}
				>
					<div style={{...styles.cardUpperBar, backgroundColor: getCardColor(o)}} >
						<div style={{height: '100%', width: '100px'}}>
							<Avatar style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '20px', marginBottom: '20px'}} src={o.style.icon} />
						</div>
						<div>

							<div style={{marginTop: '12px'}}>
								<Typography variant='h5'>
									{o.name}
								</Typography>
							</div>
							
							<div>
								<Typography variant='subtitle1'>	
									{o.info ? o.info.subdomain : ''}
								</Typography>
							</div>
							
						</div>
					</div>
					<div style={{width: '100%', height: '40px', paddingTop: '5px', paddingLeft: '10px'}}>
						{chips}
					</div>
					<div style={styles.textBox}>
						<Typography variant='subtitle1'>
						{o.info ? o.info.description : ''}
						</Typography>
					</div>

				</Paper>
			)})}

			{!requestDone ? 
			<div style={styles.card}>
				<Skeleton variant="rect" width={380} height={220} />
			</div>
			: <div/>}
			{!requestDone ? 
			<div style={styles.card}>
				<Skeleton variant="rect" width={380} height={220} />
			</div>
			: <div/>}
			{!requestDone ? 
			<div style={styles.card}>
				<Skeleton variant="rect" width={380} height={220} />
			</div>
			: <div/>}
			
		</div>

    </div>
  );
}

const styles = {
 outterDiv: {
	width: '100%',
	height: '100%',
 },
 topBar: {
	height: '80px',
	backgroundColor: 'rgb(109,109,109)'
 },
 innerContent: {
	height: '100%',
	marginLeft: '70px',
	marginTop: '50px',
	marginRight: '70px',
	marginBottom: '50px',
	flexFlow: 'wrap',
	display: 'flex'
 },
 card: {
	margin: '30px',
	width: '380px',
	height: '220px',
	cursor: 'pointer'
 },
 cardUpperBar: {
	width: '100%',
	height: '80px',
	borderTopRightRadius: '10px',
	borderTopLeftRadius: '10px',
	backgroundColor: 'orange',
	display: 'flex'
},
textBox: {
	maxWidth: '330px',
    maxHeight: '80px',
    marginLeft: '20px',
    overflow: 'hidden',
    display: 'Webkit-box',
	WebkitLineClamp: 3,
    marginLeft: '20px'
}


};

export default Organizations;
