import React, { useState, useEffect } from 'react';
import 'fontsource-roboto';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import firebase from "firebase/app";
import {VerifyAuth, goTo, getDate, baseURL} from './webHandler';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {useSessionStorage} from "react-use-storage";
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';




import {
    BrowserRouter as Router,
    useLocation
} from "react-router-dom";

function Essays() {
	const [essays, setEssays] = useState([]);
	const [requestDone, setRequestDone] = useState(false);
	const [bearer, setBearer] = useSessionStorage("bearer");
	const [addingNewEssay, setAddingNewEssay] = useState(false);
	const [uidUser, setUidUser] = useState("");
	// redoão
	const [typeTxt, setTypeTxt] = useState("");
	const [titleTxt, setTitleTxt] = useState("");
	const [subTitleTxt, setSubTitleTxt] = useState("");
	const [descTxt, setDescTxt] = useState("");
	const [startTime, setStartTime] = useState();
	const [endTime, setEndTime] = useState();
	const [categoria, setCategoria] = useState('');
	const [coletanea, setColetanea] = useState("");
	const [essayGradeMin, setEssayGradeMin] = useState(0);
	const [essayGradeMax, setEssayGradeMax] = useState(0);

	//
	//comp
	const [nameComp, setNameComp] = useState("");
	const [descComp, setDescComp] = useState("");
	const [reComp, setReComp] = useState("");
	const [minGrade, setMinGrade] = useState(0);
	const [maxGrade, setMaxGrade] = useState(0);
	const [breakComp, setBreakComp] = useState(0);
	const [moldeText, setMoldText] = useState("");
	//
	const [listTest, setListTest] = useState([]);
	const [listComp, setListComp] = useState({});
	const [showingLoad, setShowingLoad] = useState(false);

	const handleChange2 = (event) => {
		setCategoria(event.target.value);
	  };

	const [state, setState] = React.useState({
		checkedB: false,
	  });

	  const handleChange = (event) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	  };



	  function getSteps() {
		return ['Criar Redação', 'Inserir competências', 'Comentario final e criar'];
	  }

	  function getStepContent(step) {
		switch (step) {
		  case 0:
			return <div><TextField
			style={{width: '150px', marginRight: '10px', marginBottom: '20px'}}
			 id="outlined-basic"
			 label="Tipo"
			 variant="outlined"
			 value={typeTxt}
			 onChange={(ev) => {setTypeTxt(ev.target.value)}}
			error={typeTxt == "" && typeTxt.length == 0}
			id="outlined-error"
			label="Tipo"
			defaultValue="Hello World"
			variant="outlined"
			 />
			<TextField
			style={{width: '292px', marginRight: '10px', marginBottom: '20px'}}
			 id="outlined-basic"
			 label="Titulo"
			 variant="outlined"
			 value={titleTxt}
			 onChange={(ev) => {setTitleTxt(ev.target.value)}}
			 error={titleTxt == "" && titleTxt.length == 0}
			 id="outlined-error"
			 label="Titulo"
			 defaultValue="Hello World"
			 variant="outlined"
			 />
			<TextField
			style={{width: '150', marginRight: '10px', marginBottom: '20px'}}
			 id="outlined-basic"
			 label="Subtitulo"
			 variant="outlined"
			 value={subTitleTxt}
			 onChange={(ev) => {setSubTitleTxt(ev.target.value)}}
			 error={subTitleTxt == "" && subTitleTxt.length == 0}
			 id="outlined-error"
			 label="Subtitulo"
			 defaultValue="Hello World"
			 variant="outlined"
			 />
			<FormControl variant="outlined">
				<InputLabel id="demo-simple-select-outlined-label">Categoria</InputLabel>
				<Select
				style={{width: '228px'}}
				labelId="demo-simple-select-outlined-label"
				id="demo-simple-select-outlined"
				value={categoria}
				onChange={handleChange2}
				label="Categoria"
				error={categoria == "" && categoria.length == 0}
				id="outlined-error"
				label="Subtitulo"
				defaultValue="Hello World"
				variant="outlined"
				>
				<MenuItem value={'Refeitura'}>Refeitura</MenuItem>
				<MenuItem value={'Redação'}>Redação</MenuItem>
				<MenuItem value={'Simulado'}>Simulado</MenuItem>
				<MenuItem value={'Diagnóstica'}>Diagnóstica</MenuItem>
				<MenuItem value={'Recuperação'}>Recuperação</MenuItem>
				<MenuItem value={'Tarefa'}>Tarefa</MenuItem>
				</Select>
			</FormControl>
			<TextField
			style={{width: '450px', marginRight: '10px', marginBottom: '20px'}}
			 id="outlined-basic"
			 label="Coletanea"
			 variant="outlined"
			 value={coletanea}
			 onChange={(ev) => {setColetanea(ev.target.value)}}
			 error={coletanea == "" && coletanea.length == 0}
			 id="outlined-error"
			 label="Coletanea"
			 defaultValue="Hello World"
			 variant="outlined"
			 />
			 <TextField
			 style={{width: '450px', marginRight: '10px', marginBottom: '20px'}}
			  id="outlined-multiline-static"
			  label="Descrição"
			  multiline
			  rows={4}
			  variant="outlined"
			  value={descTxt}
			 onChange={(ev) => {setDescTxt(ev.target.value)}}
			 error={descTxt == "" && descTxt.length == 0}
			 id="outlined-error"
			 label="Descrição"
			 defaultValue="Hello World"
			 variant="outlined"
				/>
			<br></br>
			<label>Molde de texto - <b>Opcional</b></label>
			<TextField
			style={{width: '450px', marginRight: '10px', marginBottom: '20px'}}
			 id="outlined-basic"
			 variant="outlined"
			 value={moldeText}
			 onChange={(ev) => {setMoldText(ev.target.value)}}
			 id="outlined-error"
			 defaultValue="Hello World"
			 variant="outlined"
			 />
			<TextField
			style={{width: '220px',marginRight: '10px', marginBottom: '20px'}}
			id="filled-number"
			label="Nota minima"
			type="number"
			value={essayGradeMin}
			 onChange={(ev) => {setEssayGradeMin(ev.target.value)}}
			InputLabelProps={{
				shrink: true,
			}}
			variant="outlined"
			error={essayGradeMin == null}
			 id="outlined-error"
			 label="Nota minima"
			 defaultValue="Hello World"
			 variant="outlined"
			/>
			<TextField
			style={{width: '220px',marginRight: '10px', marginBottom: '20px'}}
			id="filled-number"
			label="Nota maxima"
			type="number"
			value={essayGradeMax}
			 onChange={(ev) => {setEssayGradeMax(ev.target.value)}}
			InputLabelProps={{
				shrink: true,
			}}
			variant="outlined"
			error={essayGradeMax == null}
			 id="outlined-error"
			 label="Nota maxima"
			 defaultValue="Hello World"
			 variant="outlined"
			/>
			<TextField
			style={{marginRight: '10px', marginBottom: '20px'}}
			id="datetime-local"
			label="Data de vigor"
			type="datetime-local"
			defaultValue="2025-02-01T00:00"
			value={startTime}
			 onChange={(ev) => {setStartTime(ev.target.value)}}
			InputLabelProps={{
			  shrink: true,
			}}
			error={startTime == null}
			 id="outlined-error"
			 label="Data de vigor"
			 defaultValue="2025-03-01T00:00"
			  />
			<TextField
			style={{marginRight: '10px', marginBottom: '20px'}}
			id="datetime-local"
			label="Data limite para entrega"
			type="datetime-local"
			defaultValue="2025-01-01T00:00"
			value={endTime}
			 onChange={(ev) => {setEndTime(ev.target.value)}}
			InputLabelProps={{
			  shrink: true,
			}}
			error={endTime == null}
			 id="outlined-error"
			 label="Data limite para entrega"
			 defaultValue="2025-01-01T00:00"
			  />
			  </div>
		  case 1:
			return <div><TextField
			style={{width: '210px', marginRight: '10px', marginBottom: '20px'}}
			 id="outlined-basic"
			 label="Nome da competência"
			 variant="outlined"
			 value={nameComp}
			 onChange={(ev) => {setNameComp(ev.target.value)}}
			 error={nameComp == "" && nameComp.length == 0}
			 id="outlined-error"
			 label="Nome da competência"
			 defaultValue="Hello World"
			 variant="outlined"
			 />
			 <TextField
			style={{width: '230px', marginRight: '10px', marginBottom: '20px'}}
			 id="outlined-basic"
			 label="Descrição da competência"
			 variant="outlined"
			 value={descComp}
			 onChange={(ev) => {setDescComp(ev.target.value)}}
			 error={descComp == "" && descComp.length == 0}
			 id="outlined-error"
			 label="Descrição da competência"
			 defaultValue="Hello World"
			 variant="outlined"
			 />
			 <TextField
			 style={{width: '450px', marginRight: '10px', marginBottom: '20px'}}
			  id="outlined-multiline-static"
			  label="Resumo da competência"
			  multiline
			  rows={4}
			  variant="outlined"
			  value={reComp}
			 onChange={(ev) => {setReComp(ev.target.value)}}
			 error={reComp == "" && reComp.length == 0}
			 id="outlined-error"
			 label="Resumo da competência"
			 defaultValue="Hello World"
			 variant="outlined"
				/>
			<TextField
			style={{width: '144px',marginRight: '10px', marginBottom: '20px'}}
			id="filled-number"
			label="Nota minima"
			type="number"
			value={minGrade}
			 onChange={(ev) => {setMinGrade(ev.target.value)}}

			InputLabelProps={{
				shrink: true,
			}}
			variant="outlined"
			error={minGrade == null}
			 id="outlined-error"
			 label="Nota minima"
			 defaultValue="Hello World"
			 variant="outlined"
			/>
			<TextField
			style={{width: '144px', marginRight: '10px', marginBottom: '20px'}}
			id="filled-number"
			label="Nota maxima"
			type="number"
			value={maxGrade}
			 onChange={(ev) => {setMaxGrade(ev.target.value)}}
			InputLabelProps={{
				shrink: true,
			}}
			variant="outlined"
			error={maxGrade == null}
			 id="outlined-error"
			 label="Nota maxima"
			 defaultValue="Hello World"
			 variant="outlined"
			/>
			<TextField
			style={{width: '141px', marginRight: '10px', marginBottom: '20px'}}
			id="filled-number"
			label="Breakpoint"
			type="number"
			value={breakComp}
			 onChange={(ev) => {setBreakComp(ev.target.value)}}
			InputLabelProps={{
				shrink: true,
			}}
			variant="outlined"
			error={breakComp == null}
			 id="outlined-error"
			 label="Breakpoint"
			 defaultValue="Hello World"
			 variant="outlined"
			/>
			<Button variant="contained" color="primary" style={{marginLeft: '343px'}} onClick={() => addComp()} disabled={nameComp.length == 0 || descComp.length == 0 || reComp.length == 0 || minGrade == null || maxGrade == null || breakComp == null}>
				Adicionar
			</Button>
			{/* Lista de competencias mostrando para o cliente e depois enviando um array de competencias */}
			<div style={{marginTop: '20px'}}>
			<h4>Competências registradas</h4>
			{listTest.length > 0 ?
			<CompList comps={listTest}>
			</CompList>
			: <div>
				<p>Não existe competências registradas até o momento.</p>
			</div>
			}
			</div></div>;
		  case 2:
			return <div><FormControlLabel
			style={{marginTop: '10px', marginLeft: '0px'}}
			control={<Switch
				checked={state.checkedB}
				onChange={handleChange}
				name="checkedB"
				color="primary"
			  />}
			label="Comentário final"
			labelPlacement="start"
			/></div>;
		  default:
			return 'Unknown step';
		}
	  }

	  const useStyles = makeStyles((theme) => ({
		root: {
		  width: '600px',
		},
		button: {
		  marginTop: theme.spacing(1),
		  marginRight: theme.spacing(1),
		},
		actionsContainer: {
		  marginBottom: theme.spacing(2),
		},
		resetContainer: {
		  padding: theme.spacing(3),
		},
	  }));

	function VerticalLinearStepper() {
		const classes = useStyles();
		const [activeStep, setActiveStep] = React.useState(0);
		const steps = getSteps();

		const handleNext = () => {
		  setActiveStep((prevActiveStep) => prevActiveStep + 1);
		  if(activeStep === 2){
			  createEssay();
		  }
		};

		const handleBack = () => {
		  setActiveStep((prevActiveStep) => prevActiveStep - 1);
		};

		const handleReset = () => {
		  setActiveStep(0);
		};

		return (
		  <div className={classes.root}>
			<Stepper activeStep={activeStep} orientation="vertical">
			  {steps.map((label, index) => (
				<Step key={label}>
				  <StepLabel>{label}</StepLabel>
				  <StepContent>
					<Typography>{getStepContent(index)}</Typography>
					<div className={classes.actionsContainer}>
					  <div>
						<Button
						  disabled={activeStep === 0}
						  onClick={handleBack}
						  className={classes.button}
						>
						  Voltar
						</Button>
						<Button
						  disabled={typeTxt.length == 0 || titleTxt.length == 0 || subTitleTxt.length == 0 || descTxt.length == 0 || startTime == null || endTime == null || categoria.length == 0 || coletanea.length == 0 || essayGradeMin == null || essayGradeMax == null}
						  variant="contained"
						  color="primary"
						  onClick={handleNext}
						  className={classes.button}
						>
						  {activeStep === steps.length - 1 ? 'Criar' : 'Proximo'}
						</Button>
					  </div>
					</div>
				  </StepContent>
				</Step>
			  ))}
			</Stepper>
			{/* {activeStep === steps.length && (
			  <Paper square elevation={0} className={classes.resetContainer}>
				<Typography>All steps completed - you&apos;re finished</Typography>
				<Button onClick={handleReset} className={classes.button}>
				  Reset
				</Button>
			  </Paper>
			)} */}
		  </div>
		);
	  }

	class CompList extends React.Component {
		constructor(props){
			super(props);
			this.state = {comps: this.props.comps};
		}


		render() {
			return(
				this.state.comps.map(c => {
					return(
						<div key={ c.index_comp }>
							<Card style={{width: '450px', marginTop: '20px'}}>
							<CardContent>
								<Typography color="textSecondary" gutterBottom>
								{c.name}
								</Typography>
								<Typography variant="h5" component="h4">
								{c.description}
								</Typography>
								<Typography  color="textSecondary">
								{c.content}
								</Typography>
								<Typography variant="body2" component="p">
								Nota minima: {c.grade.min} ------ Nota maxima: {c.grade.max}
								</Typography>
							</CardContent>
							<CardActions>
								<Button size="small" onClick={() => this.deleteComp()}>Remover</Button>
							</CardActions>
							</Card>
					</div>
					);
				   }
				)
			)
		}



		deleteComp = (index_comp) => {
			// console.log('deletado');
			const temp_list_comps = this.state.comps;
			temp_list_comps.splice(index_comp, 1);
			this.setState({comps: temp_list_comps});
		}


	}

	function createEssay(){
		var dateStart = new Date(startTime);
		dateStart =  dateStart.getTime();
		dateStart = dateStart / 1000;
		var dateEnd = new Date(endTime);
		dateEnd = dateEnd.getTime();
		dateEnd = dateEnd / 1000;
		const 	dataEssay = ({
			title: titleTxt,
			subTitle: subTitleTxt,
			category: categoria,
			collectanea:{
				url: coletanea,
			},
			info: {
				description: descTxt
			},
			grade: {
				min: Number(essayGradeMin),
				max: Number(essayGradeMax),
			},
			time: {
				start: dateStart * 1000,
				end: dateEnd * 1000
			},
			moldText: moldeText
		})
		const dataCritirea = {
			config: {
				audio: false, //Mandar sempre falso
				final: state.checkedB, // Coloca uma opção pra selecionar isso na interface (Comentário final: Sim/Não)
				grade: { //Notas máximas e mínimas que também estavam na primeira request
					min: Number(essayGradeMin),
					max: Number(essayGradeMax),
				}
			},
			criteria: listComp,

		}
				// console.log(dataCritirea);
		VerifyAuth(bearer, setBearer, function (tokenA, currentUser) {
			setShowingLoad(true);
			var xhr9 = new XMLHttpRequest();
			xhr9.addEventListener("load", () => {
				var textIDE = JSON.parse(xhr9.responseText).textID
				var xhr10 = new XMLHttpRequest();
					xhr10.addEventListener("load", () => {
						setTypeTxt("");
						setTitleTxt("");
						setSubTitleTxt("");
						setDescTxt("");
						setStartTime();
						setEndTime();
						setCategoria("");
						setColetanea("");
						setEssayGradeMin(0);
						setEssayGradeMax(0);
						setNameComp("");
						setDescComp("");
						setReComp("");
						setMaxGrade(0);
						setMinGrade(0);
						setBreakComp(0);
						setListTest([]);
						setMoldText("");
						state.checkedB = false;
							VerifyAuth(bearer, setBearer, function (tokenA, currentUser){
								//console.log(tokenA)
								var xhr = new XMLHttpRequest();
									xhr.addEventListener('load', () => {
										setShowingLoad(false);
										setRequestDone(true);
										setEssays(JSON.parse(xhr.responseText));
										setAddingNewEssay(false);
									});
									xhr.open("GET", baseURL+'o/'+query.get("oid")+'/groups/'+query.get("groupId")+'/texts/list', true);

									xhr.setRequestHeader("authorization", 'Bearer ' + tokenA);
									xhr.send();
							})
					});
					xhr10.open(
					"POST",
					baseURL +
						"o/" +
						query.get("oid") +
						"/groups/" +
						query.get("groupId") +
						"/texts/" +
						textIDE +
						"/assignments/" +
						"set?draft=false",
					true
					);
					xhr10.setRequestHeader("Content-Type", "application/json");
					xhr10.setRequestHeader("authorization", "Bearer " + tokenA);
					xhr10.send(JSON.stringify(dataCritirea));
				});
			xhr9.open(
			  "POST",
			  baseURL +
				"o/" +
				query.get("oid") +
				"/groups/" +
				query.get("groupId") +
				"/texts/" +
				"create",
			  true
			);
			xhr9.setRequestHeader("Content-Type", "application/json");
			xhr9.setRequestHeader("authorization", "Bearer " + tokenA);
			xhr9.send(JSON.stringify(dataEssay));
		  });
	}

	function addComp(){
		// console.log('adicionado');
		// console.log(nameComp);
		// console.log(descComp);
		// console.log(reComp);
		// console.log(minGrade);


		const compsTest = {
			name: nameComp,
			description: descComp,
			tag: "teste",
			content: reComp,
			grade: {
				min: Number(minGrade),
				max: Number(maxGrade),
				breakpoint: Number(breakComp),
			},
		};

		var temp = listTest;
		temp.push(compsTest);
		setListComp({...temp});
		setListTest([...temp]);

		setNameComp("");
		setDescComp("");
		setReComp("");
		setMaxGrade(0);
		setMinGrade(0);
		setBreakComp(0);
	}

	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}
	let query = useQuery();

  useEffect(() => {
	setRequestDone(false);
	VerifyAuth(bearer, setBearer, function (tokenA, currentUser){
		//console.log(tokenA)
		setUidUser(currentUser.uid);
		var xhr = new XMLHttpRequest();
			xhr.addEventListener('load', () => {
				setRequestDone(true);
				setEssays(JSON.parse(xhr.responseText));
			});
			if(query.get("year")){
				xhr.open("GET", baseURL+'o/'+query.get("oid")+'/groups/'+query.get("groupId")+'/texts/'+query.get("year")+'/list', true);
			}else{
				xhr.open("GET", baseURL+'o/'+query.get("oid")+'/groups/'+query.get("groupId")+'/texts/list', true);
			}

			xhr.setRequestHeader("authorization", 'Bearer ' + tokenA);
			xhr.send();
	})

  }, []);

  const essayClick = function(o){
	// console.log(o)
	goTo('texts?oid='+query.get("oid")+'&groupId='+query.get("groupId")+'&textId='+o.textID+'&groupName='+ query.get('groupName')+'&year='+query.get("year"))
  }

  return (
		<div style={styles.outterDiv}>

		<div style={styles.topBar}>
			<Typography variant='h3' style={{paddingTop: '8px', paddingLeft: '20px'}}>
				Redações - {query.get('groupName')}
			</Typography>
		</div>
		<div style={styles.innerContent}>

			<Modal
				open={addingNewEssay}
				onClose={() => setAddingNewEssay(false)}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div style={{width: '600px', height: '623px', backgroundColor: 'white', margin: 'auto', marginTop: '10vh'}}>
				{/* <SimpleBar forceVisible="y" autoHide={true} style={{ maxHeight: '600px' }}> */}
				<div id="scroll" style={{overflow: 'auto'}}>
				<Backdrop open={showingLoad} style={{color: '#fff', zIndex: '999'}}>
				<CircularProgress color="inherit" />
				</Backdrop>
					<div style={{maxHeight: '600px'}}>
						{VerticalLinearStepper()}
						</div>
					</div>
				{/* </SimpleBar> */}
				</div>

			</Modal>
			<div style={styles.innerContent}>

				{essays.map(function(o) {
					return (

					<Paper
						elevation={3}
						style={styles.card}
					>
						<div style={styles.cardUpperBar} >
							<div>

								<div style={{margin: '20px'}}>
									<Typography variant='h4'>
										{o.title}
									</Typography>
								</div>

								<div>
									<Typography variant='subtitle1'>
										{o.info ? o.info.subdomain : ''}
									</Typography>
								</div>

							</div>
						</div>
						<div style={{width: '100%', height: '30px', paddingTop: '5px', paddingLeft: '20px'}}>
							<Chip size="small" label={o.category} style={{marginRight: '5px'}} />
						</div>
						<div style={styles.textBox}>
							{/* <Typography variant='subtitle1' style={{fontWeight: 600}}>
								{o.info ? o.info.description : ''}
							</Typography> */}
							<Typography variant='subtitle1'>
								Data de início: {o.time ? getDate(o.time.start, true) : ''}
							</Typography>
							<Typography variant='subtitle1'>
								Data de término: {o.time ? getDate(o.time.end, true) : ''}
							</Typography>
						</div>

						<div style={{height: '1px', width: '100%', backgroundColor: '#bbbbbb', marginTop: '10px'}}/>

						<Button variant="contained" style={{float: 'right', margin: '7px', height: '30px'}} onClick={() => essayClick(o)}>
							Correção
						</Button>
					</Paper>
				)})}

				{!requestDone ?
				<div style={styles.card}>
					<Skeleton variant="rect" width={720} height={260} />
				</div>
				: <div/>}
				{!requestDone ?
				<div style={styles.card}>
					<Skeleton variant="rect" width={720} height={260} />
				</div>
				: <div/>}
				{!requestDone ?
				<div style={styles.card}>
					<Skeleton variant="rect" width={720} height={260} />
				</div>
				: <div/>}

			</div>

		</div>
		<div style={{position: 'fixed', bottom: '20px', right: '20px'}}>
			{uidUser != "g-AmAeHRTKt4A5qvUqT2xiJ4" && uidUser != "Ry0xn02sg8SV3KJgf7qVdgpIv7H3" ? (
				<div />
			) : (
			<IconButton color="primary" aria-label="upload picture" component="span" onClick={() => setAddingNewEssay(true)} disabled={uidUser != "g-AmAeHRTKt4A5qvUqT2xiJ4" && uidUser != "Ry0xn02sg8SV3KJgf7qVdgpIv7H3"}>
				<AddCircleIcon style={styles.zoomIcon} style={{fontSize: '50px'}}/>
			</IconButton>
			)}
		</div>
	</div>
  );
}

const styles = {
 outterDiv: {
	width: '100%',
	height: '100%',
 },
 topBar: {
	height: '80px',
	backgroundColor: 'rgb(109,109,109)'
 },
 innerContent: {
	height: '100%',
	marginLeft: '70px',
	marginTop: '50px',
	marginRight: '70px',
	marginBottom: '50px',
	flexFlow: 'wrap',
	display: 'flex',
	justifyContent: 'center'
 },
 card: {
	margin: '30px',
	width: '720px',
	height: '280px',
	cursor: 'pointer'
 },
 cardUpperBar: {
	width: '100%',
	height: '100px',
	borderTopRightRadius: '10px',
	borderTopLeftRadius: '10px',
	display: 'flex'
},
textBox: {
	maxWidth: '330px',
    maxHeight: '80px',
    marginLeft: '20px',
    overflow: 'hidden',
    display: 'Webkit-box',
	WebkitLineClamp: 3,
	marginLeft: '20px',
	marginTop: '10px'
},
};

export default Essays;
